import { useRequest } from '@/composables/useRequest';
import { API_VERSION, STUDIO_API_URL } from '@/constants/api.const';
import { ApiMethod } from '@/enums/api.enum';
import type { ConfirmationType } from '@/types/common.type';
import type { DuplicatedProductNameRequest } from '@/types/product/product-request.type';
import type {
  CreateProjectProductRequest,
  ProductProjectRequest
} from '@/types/project-product/project-product-model.type';
import type { FetchProjectRequest } from '@/types/project-product/project-product-request.type';
import type {
  CreateProjectResponse,
  ProductProjectResponse,
  ProjectListResponse
} from '@/types/project-product/project-product-response.type';
import { camelToSnake, generateHeader } from '@/utils/api.util';

const apiUrl = `${STUDIO_API_URL}/${API_VERSION}`;

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435955145
export const fetchProjectListApi = async (
  params: FetchProjectRequest,
  showCommonError?: boolean
): Promise<ProjectListResponse | undefined> => {
  const { data } = await useRequest<ProjectListResponse>(`${apiUrl}/projects`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    params: camelToSnake(params),
    showCommonError: showCommonError ?? true
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435955052
export const createProjectApi = async (
  params: CreateProjectProductRequest
): Promise<CreateProjectResponse | undefined> => {
  const { data } = await useRequest<CreateProjectResponse>(`${apiUrl}/projects`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    data: camelToSnake(params),
    method: ApiMethod.Post
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=450850816
export const createProjectBulkProductsApi = async (params: {
  groupId: string;
  projectName: string;
  products: ProductProjectRequest[];
}): Promise<
  | {
      projectId: string;
      products: ProductProjectResponse[];
    }
  | undefined
> => {
  const { data } = await useRequest<{
    projectId: string;
    products: ProductProjectResponse[];
  }>(`${apiUrl}/projects/bulk`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    data: camelToSnake(params),
    method: ApiMethod.Post,
    cancelAfter: 0
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435955329
export const deleteProjectApi = async (
  projectId: string
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/projects/${projectId}`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Delete,
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=450850865
export const checkProjectProductName = async (params: {
  groupId: string;
  projectName: string;
}): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/projects/check/name`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Get,
    params: camelToSnake(params),
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=450850880
export const checkProductInProjectNameApi = async (
  params: DuplicatedProductNameRequest
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/products/check/name`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Get,
    params: camelToSnake(params),
    showCommonError: false,
    showLoading: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=453486243
export const checkProductListInProjectNameApi = async (params: {
  groupId: string;
  productNames: string[];
  languageCd: string;
}): Promise<{ duplicated: string[] } | undefined> => {
  // const productNames = params.productNames.join(',');
  const { groupId, productNames, languageCd } = params;
  const { data } = await useRequest<{ duplicated: string[] }>(`${apiUrl}/products/check/names`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Post,
    data: camelToSnake({
      groupId,
      productNames,
      languageCd
    })
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435955106
export const createProductInProjectApi = async (params: {
  projectId: string;
  productName: string;
  productType: string;
}): Promise<{ productNo: number } | undefined> => {
  const { data } = await useRequest<{ productNo: number }>(`${apiUrl}/products`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    showCommonError: false,
    data: camelToSnake(params),
    method: ApiMethod.Post
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=450850831
export const createProductBulkInProjectApi = async (params: {
  projectId: string;
  products: ProductProjectRequest[];
}): Promise<
  | {
      products: ProductProjectResponse[];
    }
  | undefined
> => {
  const { data } = await useRequest<{
    products: {
      productName: string;
      productNo: string;
    }[];
  }>(`${apiUrl}/products/bulk`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    data: camelToSnake(params),
    method: ApiMethod.Post,
    cancelAfter: 0
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=450850891
export const checkRegisterProductInProjectBulkApi = async (params: {
  projectId: string;
  count?: number;
}): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/products/check/count`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Get,
    params: camelToSnake(params),
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=453497904
export const checkRegisterProjectProductApi = async (params: { groupId: string }) => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/projects/check/count`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Get,
    params: camelToSnake(params),
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435955337 ---> OLD
// https://wiki.smilegate.net/pages/viewpage.action?pageId=459625967 ---> NEW
export const deleteRequestProductApi = async (params: {
  productNo: number;
  deleteRequestYn: ConfirmationType;
}): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/products/delete-request`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Put,
    data: camelToSnake(params),
    showCommonError: false
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=435955286
export const modifyProjectNameApi = async (params: {
  projectId: string;
  projectName: string;
}): Promise<{ success: boolean } | undefined> => {
  const { projectId, projectName } = params;
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/projects/${projectId}`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Put,
    data: camelToSnake({ projectName })
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=459625965
export const modifyProductSaleStatusApi = async (
  params: {
    productNo: number;
    saleYn: ConfirmationType;
  },
  showCommonError?: boolean
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/products/sale`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Put,
    data: camelToSnake(params),
    showCommonError
  });

  return data;
};

// https://wiki.smilegate.net/pages/viewpage.action?pageId=459625960
export const modifyProductDisplayApi = async (
  params: {
    productNo: number;
    displayYn: ConfirmationType;
  },
  showCommonError?: boolean
): Promise<{ success: boolean } | undefined> => {
  const { data } = await useRequest<{ success: boolean }>(`${apiUrl}/products/display`, {
    headers: {
      ...generateHeader('X-Nation', 'X-Lang')
    },
    method: ApiMethod.Put,
    data: camelToSnake(params),
    showCommonError
  });

  return data;
};
